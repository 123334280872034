<template>
    <div>

        <ListDialog v-if="showListDialog" v-bind="listDialogParameters"
                    v-on:hidden="showListDialog = false"/>

        <RecordDialog v-if="showRecordDialog" v-bind="recordDialogParameters"
                      v-on:hidden="showRecordDialog = false"
                      v-on:model-created="onModelCreated"
                      v-on:ok="fetchCrud"/>

        <div class="card st-framework-card">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                            <font-awesome-icon :icon="icon" size="sm"/>
                        </td>
                        <td style="text-align: center;">
                            {{ $i18n.tc('translations.' + name, 2) }}
                        </td>
                        <td style="width: 50px;"/>
                    </tr>
                </table>
            </div>

            <div class="card-body" v-if="pageLoaded">

                <b-input-group size="sm" class="mb-3">
                    <b-input-group-prepend is-text>
                        <font-awesome-icon :icon="['fas', 'search']" size="sm"/>
                    </b-input-group-prepend>
                    <b-form-input ref="filter" type="search"
                                  :placeholder="$i18n.tc('translations.'+filterPlaceholder,1)"
                                  v-model="pageInfo.filter"
                                  v-on:input="debounceInput"/>
                    <b-input-group-append>
                        <template v-for="(action,index) in actions">
                            <ButtonAction :key="action.name + '-' + index" :action="action"
                                          v-on:create-row="createModel"
                                          v-if="action.type === 'BUTTON' && action.placement === 'FILTERRIGHT'"/>
                            <SelectAction :key="action.name + '-' + index" :action="action"
                                          v-on:document-filter="onDocumentFilter"
                                          v-if="action.type === 'SELECT' && action.placement === 'FILTERRIGHT'"/>
                            <RouteAction :key="action.name + '-' + index" :action="action"
                                         :data="data"
                                         v-if="action.type === 'ROUTE' && action.placement === 'FILTERRIGHT'"/>
                        </template>
                    </b-input-group-append>
                </b-input-group>

                <CrudTable :key="'crudtable-'+crudReload" :data="data" :fields="fields" :actions="actions" :pageInfo="pageInfo" :state="state"
                           v-on:delete-row="deleteModel"
                           v-on:detail-row="detailModel"
                           v-on:download-event="download"
                           v-on:inline-edit="fetchCrud"
                           v-on:selection-event="selectionEvent"
                           v-on:list-row="listModel"
                           v-on:toggle-sort="toggleSort"
                           v-on:toggle-stateintable="toggleStateInTable"
                           v-on:update-row="updateModel"/>

                <CrudPaginate v-if="meta.last_page > 1" :meta="meta" :links="links"
                              v-on:change-page="changePage"/>
            </div>
        </div>

    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction.vue";
import CrudPaginate from "../components/crud/CrudPaginate";
import CrudTable from "../components/crud/CrudTable";
import FileDownload from "@/mixins/FileDownload";
import ListDialog from "@/components/dialogs/ListDialog";
import RecordDialog from "@/components/dialogs/RecordDialog";
import RouteAction from "@/components/actions/RouteAction.vue";
import * as _ from "debounce";
import SelectAction from "@/components/actions/SelectAction.vue";

export default {
    name: "Crud",
    components: {
        ButtonAction,
        CrudPaginate,
        CrudTable,
        ListDialog,
        RecordDialog,
        RouteAction,
        SelectAction,
    },
    props: ['name', 'icon', 'api', 'filterPlaceholder', 'state', 'userLanguage', 'languages'],
    mixins: [FileDownload],
    data() {
        return {
            pageLoaded: false,
            crudReload: 0,
            routeName: null,
            pageInfo: {
                current_page_link: '',
                sort_by: '',
                sort_type: '',
                filter: '',
                document_filter: null,
                stateintable: {},
            },
            data: [],
            links: [],
            meta: [],
            fields: [],
            actions: [],
            showListDialog: false,
            listDialogParameters: {
                icon: ['fas', 'user-plus'],
                title: null,
                api: null,
            },
            showRecordDialog: false,
            recordDialogParameters: {
                type: null,
                title: null,
                api: null,
                ok_event: null,
                fields: null,
                data: null,
                languages: this.languages,
                userLanguage: this.userLanguage,
            },
        }
    },
    watch: {
        name: function () {
            this.initialize();
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        changePage(link) {
            this.pageInfo.current_page_link = link;
            this.fetchCrud();
        },
        createModel(action) {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations." + this.name, 1);
            this.recordDialogParameters.api = this.api;
            if (action.routename) {
                this.routeName = action.routename;
                this.recordDialogParameters.ok_event = "model-created";
            } else {
                this.recordDialogParameters.ok_event = "ok";
            }
            this.recordDialogParameters.fields = this.fields;
            this.recordDialogParameters.data = null;
            this.showRecordDialog = true;
        },
        debounceInput: _.debounce(function () {
            this.fetchCrud();
        }, 450),
        deleteModel(data) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = data.label;
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.ok_event = "ok";
            this.recordDialogParameters.fields = this.fields;
            this.recordDialogParameters.data = data;
            this.showRecordDialog = true;
        },
        detailModel(data) {
            this.recordDialogParameters.type = "detail";
            this.recordDialogParameters.title = data.label;
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.ok_event = "ok";
            this.recordDialogParameters.fields = this.fields;
            this.recordDialogParameters.data = data;
            this.showRecordDialog = true;
        },
        download(choice) {
            this.state.loading = true;
            this.$http.post(choice.api, {
                'writer_type': choice.writer_type,
                'filter': this.pageInfo.filter
            }, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(choice, res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Crud:download():error:", error);
            });
        },
        fetchCrud() {
            this.state.loading = true;
            this.$http.post(this.pageInfo.current_page_link, this.pageInfo, {}).then((res) => {
                let tmp = res.data.data;
                tmp.filter(record => record.selected = false);
                this.data = tmp;
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.links = res.data.links;
                this.meta = res.data.meta;
                this.pageInfo.sort_by = res.data.sort_by;
                this.pageInfo.sort_type = res.data.sort_type;
                this.pageInfo.current_page_link = this.meta.path;
                this.crudReload++;
                this.pageLoaded = true;
                this.state.loading = false;
                this.$nextTick(() => {
                    if (this.$refs.filter) {
                        this.$refs.filter.focus();
                    }
                });
            }).catch((error) => {
                console.log("Crud:fetchCrud():error:", error);
            });
        },
        initialize() {
            this.pageInfo = {
                current_page_link: this.api + '/crud',
                sort_by: '',
                sort_type: '',
                filter: '',
                document_filter: null,
                stateintable: {},
            };
            this.fetchCrud();
        },
        listModel(data) {
            if (this.name === 'Role') {
                this.listDialogParameters.title = this.$i18n.t("translations.Assign Permissions to Role: {role}", {role: data.name});
                this.listDialogParameters.api = this.api + '/permissions/' + data.id;
            } else {
                this.listDialogParameters.title = this.$i18n.t("translations.Assign Roles to User: {user}", {user: data.name});
                this.listDialogParameters.api = this.api + '/roles/' + data.id;
            }
            this.showListDialog = true;
        },
        onDocumentFilter(option) {
            this.pageInfo.document_filter = option.value;
            this.fetchCrud();
        },
        onModelCreated(data) {
            this.$router.push({name: this.routeName, params: {id: data.data.id}});
        },
        selectionEvent(choice) {
            this.state.loading = true;
            this.$http.post(choice.api, {
                ...(choice.writer_type && {'writer_type': choice.writer_type}),
                'ids': choice.ids
            }, {
                ...(choice.filename && {'responseType': 'blob'}),
            }).then((res) => {
                if (choice.filename) {
                    this.fileDownload(choice, res.data);
                }
                this.state.loading = false;
            }).catch((error) => {
                console.log("Crud:selectionEvent():error:", error);
            });
        },
        toggleSort(name) {
            if (this.pageInfo.sort_by === name) {
                if (this.pageInfo.sort_type === 'asc') {
                    this.pageInfo.sort_type = 'desc';

                } else {
                    this.pageInfo.sort_type = 'asc';
                }
            } else {
                this.pageInfo.sort_by = name;
                this.pageInfo.sort_type = 'asc';
            }
            this.fetchCrud();
        },
        toggleStateInTable(field) {
            this.pageInfo.stateintable[field.name] = field.stateintable;
            this.fetchCrud();
        },
        updateModel(data) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = data.label;
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.ok_event = "ok";
            this.recordDialogParameters.fields = this.fields;
            this.recordDialogParameters.data = data;
            this.showRecordDialog = true;
        },
    }
}
</script>

<style scoped>
</style>
