import { render, staticRenderFns } from "./RecordForm.vue?vue&type=template&id=6325c499&scoped=true&"
import script from "./RecordForm.vue?vue&type=script&lang=js&"
export * from "./RecordForm.vue?vue&type=script&lang=js&"
import style0 from "./RecordForm.vue?vue&type=style&index=0&id=6325c499&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6325c499",
  null
  
)

export default component.exports